/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { isInitiallySignedIn } from 'Util/Auth';

import {
    UPDATE_CURBSIDE_DISCLAIMER_ERROR,
    UPDATE_CURBSIDE_DISCLAIMER_STATUS,
    UPDATE_CUSTOMER_DETAILS,
    UPDATE_CUSTOMER_IS_LOADING,
    UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS,
    UPDATE_CUSTOMER_PASSWORD_RESET_STATUS,
    UPDATE_CUSTOMER_SIGN_IN_STATUS,
    UPDATE_DELIVERY_DISCLAIMER_ERROR,
    UPDATE_DELIVERY_DISCLAIMER_STATUS,
    UPDATE_INSTALLATION_STATUS,
    UPDATE_INSTALLATION_STATUS_ERROR,
    UPDATE_PICKUP_ACKNOWLEDGEMENT_ERROR,
    UPDATE_PICKUP_ACKNOWLEDGEMENT_STATUS,
    UPDATE_SHIPPING_METHOD
} from './MyAccount.action';

/** @namespace ZnetPwa/Store/MyAccount/Reducer/getInitialState */
export const getInitialState = () => ({
    isSignedIn: isInitiallySignedIn(),
    passwordResetStatus: false,
    isPasswordForgotSend: false,
    isLoading: false,
    existing_installation: null,
    existingInstallationError: false,
    pickupAcknowledge: false,
    pickupAcknowledgeError: false,
    curbsideDisclaimer: false,
    deliveryDisclaimer: false,
    curbsideDisclaimerError: false,
    deliveryDisclaimerError: false,
    customer: {},
    method: '',
    scrollToError: false
});

/** @namespace ZnetPwa/Store/MyAccount/Reducer/MyAccountReducer */
export const MyAccountReducer = (
    state = getInitialState(),
    action
) => {
    const {
        status,
        customer,
        payload,
        scrollToError
    } = action;

    switch (action.type) {
    case UPDATE_CUSTOMER_SIGN_IN_STATUS:
        return {
            ...state,
            isSignedIn: status
        };

    case UPDATE_CUSTOMER_PASSWORD_RESET_STATUS:
        return {
            ...state,
            passwordResetStatus: status
        };

    case UPDATE_CUSTOMER_PASSWORD_FORGOT_STATUS:
        return {
            ...state,
            isPasswordForgotSend: !state.isPasswordForgotSend
        };

    case UPDATE_CUSTOMER_DETAILS:
        return {
            ...state,
            customer
        };
    case UPDATE_CUSTOMER_IS_LOADING:
        const { isLoading } = action;

        return {
            ...state,
            isLoading
        };
    case UPDATE_INSTALLATION_STATUS:
        return {
            ...state,
            existing_installation: payload
        };
    case UPDATE_INSTALLATION_STATUS_ERROR:
        return {
            ...state,
            scrollToError,
            existingInstallationError: payload
        };
    case UPDATE_PICKUP_ACKNOWLEDGEMENT_STATUS:
        return {
            ...state,
            pickupAcknowledge: payload
        };
    case UPDATE_PICKUP_ACKNOWLEDGEMENT_ERROR:
        return {
            ...state,
            pickupAcknowledgeError: payload
        };
    case UPDATE_CURBSIDE_DISCLAIMER_STATUS:
        return {
            ...state,
            curbsideDisclaimer: payload
        };
    case UPDATE_CURBSIDE_DISCLAIMER_ERROR:
        return {
            ...state,
            curbsideDisclaimerError: payload
        };
    case UPDATE_DELIVERY_DISCLAIMER_STATUS:
        return {
            ...state,
            deliveryDisclaimer: payload
        };
    case UPDATE_DELIVERY_DISCLAIMER_ERROR:
        return {
            ...state,
            deliveryDisclaimerError: payload
        };
    case UPDATE_SHIPPING_METHOD:
        return {
            ...state,
            method: payload
        };

    default:
        return state;
    }
};

export default MyAccountReducer;
